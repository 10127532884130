.hm{
    background-color:  #292929;
    ;
    position: absolute;
width: 100%;
height: 100%;
top: 85px;
overflow: scroll;
 white-space: nowrap;
 scroll-behavior: smooth;
}

.p.one{justify-content: end;}
.btn{
    left: 135px;
top: 90px;
background-color:  #292929;

    position: relative;

}
.rows{
    display: flex;
    scroll-behavior: smooth;
    

    width: 1280px;
height: 250px;
left: calc(50% - 1280px/2);
top: calc(50% - 198px/2 - 120px);
bottom: 20px;

background: #171717;
border-radius: 10px;
position: relative;
}

.row_image{
    margin: 22px 40px 28px 29px;

}
.rowdetails{
    cursor: pointer;
    padding-top: 25px;
}
.rowdetails>h1{
width: 353px;
height: 25px;
left: calc(50% - 353px/2 - 94.5px);
top: calc(50% - 25px/2 - 181.5px);

font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;

color: #CFCFCF;
}
