*{
    margin:0;
  }/*for changing fonts go  index .css*/
  
  .App{
    background-color: gray ;
  }/*entire app is surrounded by a div calle App is app.js*/





































































